/** Carrossel Script Home*/
function loadAppHomeJS() {
    document.getElementsByTagName('body')[0].classList.add('jvadd')
    const nextFeed = document.querySelectorAll('.carrossel__arrow')[1];
    const previousFeed = document.querySelectorAll('.carrossel__arrow')[0];
    let currentFeed = document.querySelectorAll('.carrossel__content')
    let positionFeed = document.querySelectorAll('.position');
    let positionCurrent = 0;

    nextFeed.addEventListener('click', () => {
        positionCurrent++;
        if(positionCurrent == currentFeed.length){
            positionCurrent = 0;
        };
        currentFeed.forEach((visibleFeed) => {
            visibleFeed.classList.remove('carrossel__visible');
        });
        positionFeed.forEach((positionFeed) => {
            positionFeed.classList.remove('on');
        });
        currentFeed[positionCurrent].classList.add('carrossel__visible');
        positionFeed[positionCurrent].classList.add('on');
    });

    previousFeed.addEventListener('click', () => {
        positionCurrent--;
        if(positionCurrent < 0){
            positionCurrent = currentFeed.length - 1;
        };
        currentFeed.forEach((visibleFeed) => {
            visibleFeed.classList.remove('carrossel__visible');
        });
        positionFeed.forEach((positionFeed) => {
            positionFeed.classList.remove('on');
        });
        currentFeed[positionCurrent].classList.add('carrossel__visible');
        positionFeed[positionCurrent].classList.add('on');
    });

    let positionCurrentTabOne =  document.querySelectorAll('.position')[0];
    let positionCurrentTabTwo =  document.querySelectorAll('.position')[1];
    let positionCurrentTabThree =  document.querySelectorAll('.position')[2];
    let positionCurrentTabFor =  document.querySelectorAll('.position')[3];
    let positionCurrentTabFive =  document.querySelectorAll('.position')[4];

    positionCurrentTabOne.addEventListener('click', () => {
        document.querySelectorAll('.position').forEach((i) => {
            i.classList.remove('on')
        });
        positionCurrentTabOne.classList.add('on');
        document.querySelectorAll('.carrossel__content').forEach((i) => {
            i.classList.remove('carrossel__visible')
        });
        document.getElementsByClassName('carrossel__content')[0].classList.add('carrossel__visible');
        positionCurrent = 0;
    });

    positionCurrentTabTwo.addEventListener('click', () => {
        document.querySelectorAll('.position').forEach((i) => {
            i.classList.remove('on')
        });
        positionCurrentTabTwo.classList.add('on');
        document.querySelectorAll('.carrossel__content').forEach((i) => {
            i.classList.remove('carrossel__visible')
        });
        document.getElementsByClassName('carrossel__content')[1].classList.add('carrossel__visible');
        positionCurrent = 1;
    });

    positionCurrentTabThree.addEventListener('click', () => {
        document.querySelectorAll('.position').forEach((i) => {
            i.classList.remove('on')
        });
        positionCurrentTabThree.classList.add('on');
        document.querySelectorAll('.carrossel__content').forEach((i) => {
            i.classList.remove('carrossel__visible')
        });
        document.getElementsByClassName('carrossel__content')[2].classList.add('carrossel__visible');
        positionCurrent = 2;
    });

    positionCurrentTabFor.addEventListener('click', () => {
        document.querySelectorAll('.position').forEach((i) => {
            i.classList.remove('on')
        });
        positionCurrentTabFor.classList.add('on');
        document.querySelectorAll('.carrossel__content').forEach((i) => {
            i.classList.remove('carrossel__visible')
        });
        document.getElementsByClassName('carrossel__content')[3].classList.add('carrossel__visible');
        positionCurrent = 3;
    });

    positionCurrentTabFive.addEventListener('click', () => {
        document.querySelectorAll('.position').forEach((i) => {
            i.classList.remove('on')
        });
        positionCurrentTabFive.classList.add('on');
        document.querySelectorAll('.carrossel__content').forEach((i) => {
            i.classList.remove('carrossel__visible')
        });
        document.getElementsByClassName('carrossel__content')[4].classList.add('carrossel__visible');
        positionCurrent = 4;
    });

    /**Planos scripts */
    const plansOptions = document.querySelectorAll('.plans');
    const activeFocus = document.querySelectorAll('.plans__button');

    /**Plano Especial*/
    activeFocus[0].addEventListener('mouseover', () => {
        plansOptions[0].classList.add('focus');
    });
    activeFocus[0].addEventListener('mouseout', () => {
        plansOptions[0].classList.remove('focus');
    });

    /**Plano Startup*/
    activeFocus[1].addEventListener('mouseover', () => {
        plansOptions[1].classList.add('focus');
    });
    activeFocus[1].addEventListener('mouseout', () => {
        plansOptions[1].classList.remove('focus');
    });

    /**Plano Super*/
    activeFocus[2].addEventListener('mouseover', () => {
        plansOptions[2].classList.add('focus');
    });
    activeFocus[2].addEventListener('mouseout', () => {
        plansOptions[2].classList.remove('focus');
    });

    /**Plano Pro*/
    activeFocus[3].addEventListener('mouseover', () => {
        plansOptions[3].classList.add('focus');
    });
    activeFocus[3].addEventListener('mouseout', () => {
        plansOptions[3].classList.remove('focus');
    });

    /**Blog Script*/
    // const filterOptions = document.querySelectorAll('.filter__option');

    /**Tutoriais*/
    // filterOptions[0].addEventListener('click', () => {
    //     document.getElementById('tutoriais').style.display='flex';
    //     document.getElementById('dicas').style.display='none';
    //     document.getElementById('empreendedorismo').style.display='none';
    //     document.querySelectorAll('.filter__option').forEach((i) => {
    //         i.classList.remove('checked');
    //     });
    //     filterOptions[0].classList.add('checked');
    // });

    /**Dicas*/
    // filterOptions[1].addEventListener('click', () => {
    //     document.getElementById('tutoriais').style.display='none';
    //     document.getElementById('dicas').style.display='flex';
    //     document.getElementById('empreendedorismo').style.display='none';
    //     document.querySelectorAll('.filter__option').forEach((i) => {
    //         i.classList.remove('checked');
    //     });
    //     filterOptions[1].classList.add('checked');
    // });

    /**Empreendedorismo*/
    // filterOptions[2].addEventListener('click', () => {
    //     document.getElementById('tutoriais').style.display='none';
    //     document.getElementById('dicas').style.display='none';
    //     document.getElementById('empreendedorismo').style.display='flex';
    //     document.querySelectorAll('.filter__option').forEach((i) => {
    //         i.classList.remove('checked');
    //     });
    //     filterOptions[2].classList.add('checked');
    // });
}
