function loadSideMenuJS() {
  const menuShortHamb = document.getElementsByClassName("menu__short--hamb")[0];
  const menuOpenHamb = document.getElementsByClassName("menu__open--hamb")[0];
  const menuMobileHamb = document.getElementsByClassName("menu__mobile")[0];
  const menuOpen = document.getElementsByClassName("menu__open")[0];
  const menuShort = document.getElementsByClassName("menu__short--full")[0];
  const openMask = document.getElementsByClassName("menu__open--mask")[0];

  /**Menu hamb functions */

  /**Opened hamb */
  menuOpenHamb.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  /**Short hamb */
  menuShortHamb.addEventListener("click", () => {
    menuShort.classList.add("menu__hidden");
    menuOpen.classList.remove("menu__hidden");
    document.getElementsByTagName("body")[0].classList.add("openmenu");
  });

  /**Mobile hamb */
  menuMobileHamb.addEventListener("click", () => {
    menuShort.classList.add("menu__hidden");
    menuOpen.classList.remove("menu__hidden");
    document.getElementsByTagName("body")[0].classList.add("openmenu");
  });

  /**Black mask */
  openMask.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  /**Sub-menu */
  const submenuRequest = document.getElementsByClassName(
    "menu__open__request--items"
  )[0];
  const submenuProducts = document.getElementsByClassName(
    "menu__open__products--items"
  )[0];
  const submenuCategory = document.getElementsByClassName(
    "menu__open__products--items"
  )[1];
  const submenuOperation = document.getElementsByClassName(
    "menu__open__products--items"
  )[2];
  const submenuDelivery = document.getElementsByClassName(
    "menu__open__products--items"
  )[3];
  const submenuPayments = document.getElementsByClassName(
    "menu__open__products--items"
  )[4];
  const submenuPersonalization = document.getElementsByClassName(
    "menu__open__products--items"
  )[5];

  submenuRequest.children[0].addEventListener("click", () => {
    if (submenuRequest.children[0].classList[1] == "submenu__open") {
      submenuRequest.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else if (submenuRequest.children[0].classList[2] == "submenu__open") {
      submenuRequest.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else {
      document
        .getElementsByClassName("submenu__open")[0]
        .classList.remove("submenu__open");
      submenuRequest.children[0].classList.add("submenu__open");
    }
  });

  submenuProducts.children[0].addEventListener("click", () => {
    if (submenuProducts.children[0].classList[1] == "submenu__open") {
      submenuProducts.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else if (submenuProducts.children[0].classList[2] == "submenu__open") {
      submenuProducts.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else {
      document
        .getElementsByClassName("submenu__open")[0]
        .classList.remove("submenu__open");
      submenuProducts.children[0].classList.add("submenu__open");
    }
  });

  submenuCategory.children[0].addEventListener("click", () => {
    if (submenuCategory.children[0].classList[2] == "submenu__open") {
      submenuCategory.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else if (submenuCategory.children[0].classList[3] == "submenu__open") {
      submenuCategory.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else {
      document
        .getElementsByClassName("submenu__open")[0]
        .classList.remove("submenu__open");
      submenuCategory.children[0].classList.add("submenu__open");
    }
  });

  submenuOperation.children[0].addEventListener("click", () => {
    if (submenuOperation.children[0].classList[1] == "submenu__open") {
      submenuOperation.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else if (submenuOperation.children[0].classList[2] == "submenu__open") {
      submenuOperation.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else {
      document
        .getElementsByClassName("submenu__open")[0]
        .classList.remove("submenu__open");
      submenuOperation.children[0].classList.add("submenu__open");
    }
  });

  submenuDelivery.children[0].addEventListener("click", () => {
    if (submenuDelivery.children[0].classList[2] == "submenu__open") {
      submenuDelivery.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else if (submenuDelivery.children[0].classList[3] == "submenu__open") {
      submenuDelivery.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else {
      document
        .getElementsByClassName("submenu__open")[0]
        .classList.remove("submenu__open");
      submenuDelivery.children[0].classList.add("submenu__open");
    }
  });

  submenuPayments.children[0].addEventListener("click", () => {
    if (submenuPayments.children[0].classList[2] == "submenu__open") {
      submenuPayments.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else if (submenuPayments.children[0].classList[3] == "submenu__open") {
      submenuPayments.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else {
      document
        .getElementsByClassName("submenu__open")[0]
        .classList.remove("submenu__open");
      submenuPayments.children[0].classList.add("submenu__open");
    }
  });

  submenuPersonalization.children[0].addEventListener("click", () => {
    if (submenuPersonalization.children[0].classList[2] == "submenu__open") {
      submenuPersonalization.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else if (
      submenuPersonalization.children[0].classList[3] == "submenu__open"
    ) {
      submenuPersonalization.children[0].classList.toggle("submenu__open");
      document
        .getElementsByClassName("menu__open--home")[0]
        .classList.add("submenu__open");
    } else {
      document
        .getElementsByClassName("submenu__open")[0]
        .classList.remove("submenu__open");
      submenuPersonalization.children[0].classList.add("submenu__open");
    }
  });

  /**Style menu */
  /**Home short/open */

  const homeOpen = document.getElementsByClassName("menu__open--home")[0];
  homeOpen.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  const logoMenuOpen =
    document.getElementsByClassName("menu__open--header")[0].children[1];
  logoMenuOpen.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  /**Request short/open */

  const orderCenterOpen = document.getElementsByClassName(
    "menu__open--submenu"
  )[0].children[0];
  orderCenterOpen.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  const orderHistoryOpen = document.getElementsByClassName(
    "menu__open--submenu"
  )[0].children[1];
  orderHistoryOpen.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  /**Products short/opens */

  const registerProductsOpen = document.getElementsByClassName(
    "menu__open--submenu"
  )[1].children[0];
  registerProductsOpen.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  const manageProductsOpen = document.getElementsByClassName(
    "menu__open--submenu"
  )[1].children[1];
  manageProductsOpen.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  /**Category short/open */

  const registerCategoryOpen = document.getElementsByClassName(
    "menu__open--submenu"
  )[2].children[0];
  registerCategoryOpen.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  const manageCategoryOpen = document.getElementsByClassName(
    "menu__open--submenu"
  )[2].children[1];
  manageCategoryOpen.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
  });

  /**Operation short/open*/

  //  const manageOperationOpen = document.getElementsByClassName('menu__open--submenu')[3].children[0];
  //  manageOperationOpen.addEventListener('click', () => {
  //     menuOpen.classList.add('menu__hidden');
  //     menuShort.classList.remove('menu__hidden');
  //     document.getElementsByClassName('submenu__open')[0].classList.remove('submenu__open');
  //     document.getElementsByClassName('menu__open--home')[0].classList.add('submenu__open');
  //     document.getElementsByTagName('body')[0].classList.remove('openmenu');
  //  });

  /**Delivery short/open */

  //  const manageDeliveryOpen = document.getElementsByClassName('menu__open--submenu')[4].children[0];
  //  manageDeliveryOpen.addEventListener('click', () => {
  //     menuOpen.classList.add('menu__hidden');
  //     menuShort.classList.remove('menu__hidden');
  //     document.getElementsByClassName('submenu__open')[0].classList.remove('submenu__open');
  //     document.getElementsByClassName('menu__open--home')[0].classList.add('submenu__open');
  //     document.getElementsByTagName('body')[0].classList.remove('openmenu');
  //  });

  /**Payments short/open */

  //  const managePaymentsOpen = document.getElementsByClassName('menu__open--submenu')[5].children[0];
  //  managePaymentsOpen.addEventListener('click', () => {
  //     menuOpen.classList.add('menu__hidden');
  //     menuShort.classList.remove('menu__hidden');
  //     document.getElementsByClassName('submenu__open')[0].classList.remove('submenu__open');
  //     document.getElementsByClassName('menu__open--home')[0].classList.add('submenu__open');
  //     document.getElementsByTagName('body')[0].classList.remove('openmenu');
  //  });

  /**Personalization short/open */

  //  const managePersonalizationOpen = document.getElementsByClassName('menu__open--submenu')[6].children[0];
  //  managePersonalizationOpen.addEventListener('click', () => {
  //     menuOpen.classList.add('menu__hidden');
  //     menuShort.classList.remove('menu__hidden');
  //     document.getElementsByClassName('submenu__open')[0].classList.remove('submenu__open');
  //     document.getElementsByClassName('menu__open--home')[0].classList.add('submenu__open');
  //     document.getElementsByTagName('body')[0].classList.remove('openmenu');
  //  });

  //**Not sub menu */

  const orderOperation = document.getElementsByClassName(
    "menu__open--nooption"
  )[0];
  orderOperation.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
  });

  const orderDelivery = document.getElementsByClassName(
    "menu__open--nooption"
  )[1];
  orderDelivery.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
  });

  const orderPayments = document.getElementsByClassName(
    "menu__open--nooption"
  )[2];
  orderPayments.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
  });

  const orderPersonalization = document.getElementsByClassName(
    "menu__open--nooption"
  )[3];
  orderPersonalization.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
  });

  const orderProfile = document.getElementsByClassName(
    "menu__open--nooption"
  )[4];
  orderProfile.addEventListener("click", () => {
    menuOpen.classList.add("menu__hidden");
    menuShort.classList.remove("menu__hidden");
    document.getElementsByTagName("body")[0].classList.remove("openmenu");
    document
      .getElementsByClassName("submenu__open")[0]
      .classList.remove("submenu__open");
    document
      .getElementsByClassName("menu__open--home")[0]
      .classList.add("submenu__open");
  });
}
